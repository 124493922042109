.App {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  background-image: url("https://images.unsplash.com/photo-1565130159846-0e771351fd68?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 32px;
  color: var(--clr-white);
}

main {
  width: 100%;
  height: 100%;
}

.loader {
  text-align: center;
}
