html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --fw-light: 300;
  --fw-normal: 400;
  --fw-semi: 500;
  --fw-bold: 600;
  --fs-l: 2.25rem;
  --fs-md: 1.5rem;
  --fs-body: 1rem;
  --fs-xl: 3.125rem;
  --fs-xxl: 4rem;
  --clr-white: #fff;
  --clr-lightGray: #c6c6c6;
  --clr-gray: #2d3038;
  --clr-bg: #0d0a0b;
  --clr-primary: #cb383d;
  --clr-secondary: #2581c5;
  --clr-accent: #e7e247;
}
